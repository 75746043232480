<template>
  <div class="main">
     <!-- 弹窗内容 -->
     
    <div class="one">
      <img class="bg-img" src="../assets/bg-ing.png" alt="" />
       <img class="bg-img1" src="../assets/bg-ing.png" alt="" />
      <img class="one-img" src="../assets/one.png" alt="" />
        <!-- <img class="one-img1" src="../assets/one.png" alt="" /> -->
      <img class="two-img" src="../assets/two.png" alt="" />
      <img class="two-img1" src="../assets/two.png" alt="" />
      <img class="three-img" src="../assets/three.png" alt="" />
      
      <img class="four-img" src="../assets/four.png" alt="" />
       <img class="four-img1" src="../assets/four.png" alt="" />
      <img class="five-img" src="../assets/five.png" alt="" />
      <img class="five-img1" src="../assets/five.png" alt="" />
      <img class="sex-img" src="../assets/sex.png" alt="" />
      <img class="sex-img1" src="../assets/sex.png" alt="" />
      <img
        data-wow-duration="1.5s"
        data-wow-iteration="infinite"
        class="lazi-x wow animate__animated animate__pulse"
        src="../assets/lzai-x.png"
        alt=""
      />
      <!-- <img
        data-wow-duration="1.5s"
        data-wow-iteration="infinite"
        class="money wow animate__animated animate__pulse"
        src="../assets/money.png"
        alt=""
      /> -->
     
      <img
        class="cart wow animate__animated animate__lightSpeedInRight"
        src="../assets/cart.png"
        alt=""
      />
     
      <img
        class="red wow animate__animated animate__fadeInRightBig"
        src="../assets/red.png"
        alt=""
      />
      <img
        class="jingshen wow animate__animated animate__zoomInDown"
        src="../assets/jingshen.png"
        alt=""
      />
      <div class="title wow animate__animated animate__backInDown">PEACE CAT </div>
      <div class="sub-title">
        <div class="f-s-48 wow animate__animated animate__flipInX">
          Peace cat represents world Peace, no war, no harm, one of the necessary elements of manager recovery is the absence of war, Peace cat will spread peace in the form of meme
        </div>
        <!-- <div class="f-s-48 wow animate__animated animate__flipInX">
         Mewcat is a pure meme, decentralised cryptocurrency inspired by Meme culture, active in the Solana community to get instant returns and benefits.
        </div> -->
      </div>
       
<template>
   <a data-wow-iteration="infinite" class="close wow animate__animated animate__swing" href="#" target="_blank">
      <img
               class=""
        src="../assets/close.png"
        alt=""
      />
    </a>
     <a data-wow-iteration="infinite" class="fly wow animate__animated animate__jello" href="#" target="_blank">
      <img
        data-wow-iteration="infinite"
        class=""
        src="../assets/fly.png"
        alt=""
      />
    </a>
</template>
     

      <div class="text-bg wow animate__animated animate__bounceInUp">
        <van-swipe
          @change="onChange"
          class="my-swipe"
          :autoplay="3000"
          indicator-color="white"
        >
          <van-swipe-item
            ><div class="bg-title">2024 Q2</div>
            <div class="bg-subtitle">
              Public offering and listing, public sales, exchange listing, liquidity pool establishment
            </div></van-swipe-item
          >
          <van-swipe-item>
            <div class="bg-title">2024 Q3</div>
            <div class="bg-subtitle">
              Community growth and participation, community activities, airdrop activities, partnership expansion
            </div>
          </van-swipe-item>
           <van-swipe-item>
            <div class="bg-title">2024 Q4</div>
            <div class="bg-subtitle">
            Mewcat trading robot is released, and at the same time it has a greater influence.
            </div>
          </van-swipe-item>
           <van-swipe-item>
            <div class="bg-title">2025 and later</div>
            <div class="bg-subtitle">
              Continuous innovation and expansion, new function development, international expansion continuous optimization and adjustment
            </div>
          </van-swipe-item>
        </van-swipe>

        <div class="flex al-item">
          <div class="flex" v-for="(i, index) in 4" :key="i">
            <div class="dot-d" :class="active == index ? 'active' : ''"></div>
          </div>
        </div>
      </div>

      <!-- <div class="more wow animate__animated animate__fadeInTopLeft">
        <div class="more-lzai wow animate__animated animate__headShake">
          Learn more about Mewcat (road map)
        </div>
        <img src="../assets/more.png" alt="" />
      </div> -->

      <img class="dun" src="../assets/dun.png" alt="" />
      <img
        data-wow-duration="1.5s"
        data-wow-iteration="infinite"
        class="diao-zhe wow animate__animated animate__shakeY"
        src="../assets/diao.png"
        alt=""
      />
      <div class="san-ji wow animate__animated animate__backInDown">
        <img
          data-wow-delay="2s"
          data-wow-duration="2s"
          data-wow-iteration="infinite"
          class="wow animate__animated animate__tada"
          src="../assets/san-ji.png"
          alt=""
        />
      </div>
      <div class="duihua">
        <div class="duihua-lzai wow animate__animated animate__backInUp">
          PEACE CAT will be the next gem in Solana
        </div>
        <img src="../assets/duihua.png" alt="" />
      </div>

      <div
        data-wow-duration="2.5s"
        data-wow-iteration="infinite"
        class="qu-tmd wow animate__animated animate__heartBeat"
      >
        <div class="titles">Burning LP</div>
        <div class="sub-titles">Add 100% Liquidity</div>
      </div>

      <img
        data-wow-duration="2.5s"
        data-wow-iteration="infinite"
        class="diao-si wow animate__animated animate__flash"
        src="../assets/diao-si.png"
        alt=""
      />

      <div class="why">
        <div
          data-wow-duration="2.5s"
          data-wow-iteration="infinite"
          class="why-text wow animate__animated animate__rubberBand"
        >
          How can I catch this gem?
        </div>
        <img src="../assets/why.png" alt="" />
      </div>
      <!-- s1 s -->
      <img class="big-txt" src="../assets/big-text.png" alt="" />
      <img
        class="jingshne-x wow animate__animated animate__zoomInLeft"
        src="../assets/jingshen-x.png"
        alt=""
      />

      <div class="daibi-mame">
        <div class="flex fd-cloum wow animate__animated animate__fadeInDown">
          <div class="title-name">Connect wallet</div>
          <div class="title-text">
            Download metatasks or the wallet of your choice for free from the App Store or Google Play Store. Desktop users can download the Google chrome extension by visiting metamask.io.
          </div>
         <!-- <div class="title-name">已领取Lzai：0.0</div>
          <div class="title-name">可领取Lzai：0.00</div>
           <div class="title-name">LZAI代币： 0.00</div> -->
        </div>
        
      </div>
      <!-- s1 e -->
      <!-- s2 s -->
      <img class="big-txt1" src="../assets/big-text.png" alt="" />
      <img
        class="jingshne-x1 wow animate__animated animate__zoomInLeft"
        src="../assets/jingshen-x1.png"
        alt=""
      />

      <div class="daibi-mame1">
        <div class="flex fd-cloum wow animate__animated animate__fadeInDown">
          <div class="title-name">Exchange SOL</div>
          <div class="title-text">
            The money package has SOL, which can be switched to $PEACE CAT. If you don‘t have any SOL, you can buy it directly on metamask, transfer money from another wallet, or buy it on another exchange and send it to your wallet.
          </div>
         <!-- <div class="title-name">已领取Lzai：0.0</div>
          <div class="title-name">可领取Lzai：0.00</div>
           <div class="title-name">LZAI代币： 0.00</div> -->
        </div>
        
      </div>
      <!-- s2 e -->
      <!-- s3 s -->
      <img class="big-txt2" src="../assets/big-text.png" alt="" />
      <img
        class="jingshne-x2 wow animate__animated animate__zoomInLeft"
        src="../assets/jingshen-x2.png"
        alt=""
      />

      <div class="daibi-mame2">
        <div class="flex fd-cloum wow animate__animated animate__fadeInDown">
          <div class="title-name">Trade with Raydium</div>
          <div class="title-text">
            Go to Raydium. Connect your wallet. Paste the $PEACE CAT address into Raydium, select PEACE CAT, and then confirm. Ray Metamask prompts you to enter the wallet signature and authorize the wallet.
          </div>
         <!-- <div class="title-name">已领取Lzai：0.0</div>
          <div class="title-name">可领取Lzai：0.00</div>
           <div class="title-name">LZAI代币： 0.00</div> -->
        </div>
        
      </div>
      <!-- s3 e -->
       <!-- s3 s -->
       <img class="big-txt3" src="../assets/big-text.png" alt="" />
       <img
         class="jingshne-x3 wow animate__animated animate__zoomInLeft"
         src="../assets/jingshen-x3.png"
         alt=""
       />
 
       <div class="daibi-mame3">
         <div class="flex fd-cloum wow animate__animated animate__fadeInDown">
           <div class="title-name">Exchange $PEACE CAT</div>
           <div class="title-text">
            You can exchange SOL for $PEACE CAT on Raydium.
          </div>
          <!-- <div class="title-name">已领取Lzai：0.0</div>
           <div class="title-name">可领取Lzai：0.00</div>
            <div class="title-name">LZAI代币： 0.00</div> -->
         </div>
         
       </div>
       <!-- s3 e -->
      <div class="daibi wow animate__animated animate__rollIn">
        <div
          data-wow-duration="3.5s"
          data-wow-iteration="infinite"
          data-wow-delay="1s"
          class="daibi-number wow animate__animated animate__bounce"
        >
        Token economics
        </div>
      </div>

      <div class="left-txt wow animate__animated animate__bounceInLeft">
        <div class="daibi-number">Name:PEACE CAT</div>
        <div class="daibi-number">Symbol:PEACE CAT</div>
        <div class="daibi-number">Total circulation</div>
        <div class="daibi-number">9,999,999,999</div>
        <div class="daibi-number">Liquidity:50%</div>
        <div class="daibi-number ">Listing on the exchange:8%</div>
        <div class="daibi-number">Combustion policy:25%</div>
        <div class="daibi-number">Community construction:12%</div>
        <div class="daibi-number">Airdrop activity:5%</div>
      </div>

      <!-- <img class="fire" src="../assets/fire.png" alt="" /> -->
      <img
        data-wow-duration="4s"
        data-wow-iteration="infinite"
        class="big-b wow animate__animated animate__heartBeat"
        src="../assets/big-b.png"
        alt=""
      />
      <img
      data-wow-duration="4s"
      data-wow-iteration="infinite"
      class="big-b1 wow animate__animated animate__heartBeat"
      src="../assets/big-b1.png"
      alt=""
    />

      <div
        class="yijia wow animate__animated animate__swing"
        data-wow-delay="0.5s"
        data-wow-iteration="infinite"
        data-wow-duration="2s"
      >
        <div class="daibi-number">
          Truly pure Meme meme culture
        </div>
      </div>

      <div class="lian-xi">
        <div
          data-wow-iteration="infinite"
          class="daibi-number wow animate__animated animate__rubberBand"
          data-wow-duration="2s"
        >
        Attention
        </div>
      </div>

      <img
        data-wow-iteration="infinite"
        data-wow-duration="3s"
        class="qi-fly wow animate__animated animate__zoomInLeft"
        src="../assets/qi-fly.png"
        alt=""
      />

      <div class="foodrer">
        <div class="daibi-number wow animate__animated animate__backInUp">
          Note: PEACE CAT is a pure Meme meme coin. There is no intrinsic actual value or financial return expected. There is no team. The coin is completely air, useless, and is only used for entertainment and communication of spirit
        </div>
      </div>
      <!-- <img class="caibao" src="../assets/caibao.png" alt="" /> -->
         <div class="wow animate__animated animate__bounceInUp text-bg1">
        <van-swipe
          @change="onChange"
          class="my-swipe"
          :autoplay="3000"
          indicator-color="white"
        >
          <van-swipe-item
            ><div class="bg-title"></div>
            <div class="bg-subtitle">
              Build an active and mutually supportive community environment, and encourage users to make positive contributions through direct community participation and governance.
            </div>
            <!-- <button>Join in</button> -->
            </van-swipe-item
          >
        
       
        </van-swipe>

        <div class="flex al-item">
          <div class="flex" v-for="(i, index) in 1" :key="i">
            <div class="dot-d" :class="active == index ? 'active' : ''"></div>
          </div>
        </div>
      </div>
     
      <div class="more1 wow animate__animated animate__fadeInTopLeft">
        <div class="more-lzai wow animate__animated animate__headShake">
          Community-driven
        </div>
        <img src="../assets/more.png" alt="" />
      </div>
      <div class="san-ji1 wow animate__animated animate__backInDown">
        <img
          data-wow-delay="2s"
          data-wow-duration="2s"
          data-wow-iteration="infinite"
          class="wow animate__animated animate__tada"
          src="../assets/san-ji.png"
          alt=""
        />
      </div>
       <div class="duihua1">
        <div class="duihua-lzai wow animate__animated animate__backInUp">
          Join our PEACE CAT community and you will experience a new meme economy, not only investment, but also entertainment.
        </div>
        <img src="../assets/duihua.png" alt="" />
      </div>
       <!-- <img class="bg-img1" src="../assets/bg-ing.png" alt="" /> -->
      <!-- <img class="one-img" src="../assets/one.png" alt="" />
      <img class="two-img" src="../assets/two.png" alt="" />
      <img class="three-img" src="../assets/three.png" alt="" /> -->
      <template>
        <a data-wow-iteration="infinite" class="close1 wow animate__animated animate__swing" href="#" target="_blank">
           <img
                    class=""
             src="../assets/close.png"
             alt=""
           />
         </a>
          <a data-wow-iteration="infinite" class="fly1 wow animate__animated animate__jello" href="#" target="_blank">
           <img
             data-wow-iteration="infinite"
             class=""
             src="../assets/fly.png"
             alt=""
           />
         </a>
     </template>
     
     <audio ref="musicPlayer" src="../assets/44.mp3"></audio>
      <div class="popup" v-if="show">
        <div class="p-title flex fd-cloum al-item juc-center">
          <img src="../assets/success.png" alt="" />
          <div class="succe">参与成功</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {
      showModal: false,
      active: 0,
      show: false,
      times: null,
      falg: false,
    };
  },

  mounted() {
    this.openModal(); // 页面加载完成后自动调用
    this.$nextTick(() => {
      const wow = new WOW({
        boxClass: "wow", // default
        animateClass: "animate", // default
        offset: 20, // default
        mobile: true, // default
        live: false,
        // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.
        callback: function (box) {},
      });
      wow.init();
    });
  },
  methods: {
    openModal() {
      this.showModal = true; // 显示弹窗
    },
    closeModal() {
      const musicPlayer = this.$refs.musicPlayer;
      musicPlayer.play();
      this.showModal = false; // 关闭弹窗
    },
    playMusic() {
      
    },
    open2() {
      this.show = true;
      if (!this.falg) {
        this.falg = true;
        console.log("11");
        this.times = setTimeout(() => {
          this.show = false;
          this.falg = false;
          clearTimeout(this.times);
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  overflow: hidden !important;
  // min-height: 100vh;
  .modal {
    position: fixed;
    top: 20%;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
    text-align: center;
    .tkimg{
      width: 1600px;
      margin:0 auto;
    }
  }
  .one {
    overflow: hidden;
    .bg-img {
      position: absolute;
      width: 1920px;
      height: 9000px;
      z-index: -10;
    }
 .bg-img1 {
      position: absolute;
      width: 1920px;
      top: 8100px;
      height: 5450px;
      z-index: -10;
    }
    .one-img {
      position: absolute;
      top: 0;
      width: 1920px;
      height: 1222px;
      z-index: 20;
    }
       .one-img1 {
      position: absolute;
      top: 9300px;
      width: 1920px;
      height: 1222px;
      z-index: 20;
    }
    .two-img {
      position: absolute;
      right: 0;
      top: 0;
      width: 1331px;
      height: 945px;
    }
       .two-img1 {
      position: absolute;
      right: 0;
      top: 9500px;
      width: 1331px;
      height: 945px;
    }
    .three-img {
      position: absolute;
      top: 820px;
      width: 1920px;
      height: 1621px;
      z-index: -1;
    }

    .four-img {
      position: absolute;
      top: 2200px;
      width: 1920px;
      height: 1555px;
      z-index: 1;
    }
     .four-img1 {
      position: absolute;
      top:9800px;
      width: 1920px;
      height: 1555px;
      z-index: 1;
    }
    .five-img {
      position: absolute;
      top: 3350px;
      width: 1920px;
      height: 1687px;
    }
     .five-img1 {
      position: absolute;
      top: 9300px;
      width: 1920px;
      height: 1687px;
    }
    .sex-img {
      position: absolute;
      top: 6000px;
      width: 1920px;
      height: 1558px;
    }
    .sex-img1 {
      position: absolute;
      top: 11900px;
      width: 1920px;
      height: 1558px;
    }
    .lazi-x {
      position: absolute;
      top: 21px;
      left: 29px;
      width: 100px;
      height: 108px;
      z-index: 100;
    }
    .btnmusic{
      position: absolute;
      top: 21px;
      left: 29px;
      width: 100px;
      height: 108px;
      z-index: 100;
    }
    .money {
      position: absolute;
      top: 15px;
      right: 29px;
      z-index: 100;
      width: 328px;
      text-align: center;
      line-height: 115px;
      height: 115px;
      background: url("../assets/green-bg.png") no-repeat;
      background-size: cover;
      z-index: 200;
    
      .sdt-number {
        font-size: 70px;
      }
    }
    .title {
      position: absolute;
      top: 300px;
      left: 200px;
      font-size: 170px;
      z-index: 100;
      font-style: italic;
      
    }
    .sub-title {
      position: absolute;
      top: 500px;
      left: 200px;
      width: 806px;
      font-size: 48px;
      z-index: 100;
     
    }
    .cart {
      position: absolute;
      top: 100px;
      right: 0;
      width: 1226px;
      height: 490px;
      z-index: 100;
    }
    .yellow {
      position: absolute;
      top: 180px;
      right: 640px;
      width: 336px;
      height: 656px;
      z-index: 100;
    }
    .red {
      position: absolute;
      top: 200px;
      right: 0px;
      width: 503px;
      height: 595px;
      z-index: 100;
    }
    .jingshen {
      position: absolute;
      top: 300px;
      right: 250px;
      width: 577px;
      height: 649px;
      z-index: 100;
    }
    .close {
      position: absolute;
      top: 900px;
      left: 250px;
      height: 91px;
      width: 90px;
      z-index: 100;
       img {
        width: 100%;
      
      }
    }
    .close1 {
      position: absolute;
      top: 13400px;
      left: 810px;
      height: 91px;
      width: 90px;
      z-index: 100;
       img {
        width: 100%;
      
      }
    }
    .fly {
      position: absolute;
      top: 900px;
      left: 390px;
      width: 90px;
      height: 87px;
      z-index: 100;
        img {
        width: 100%;
      
      }
    }
    .fly1 {
      position: absolute;
      top: 13400px;
      left: 970px;
      width: 90px;
      height: 87px;
      z-index: 100;
        img {
        width: 100%;
      
      }
    }
    .more {
      position: absolute;
      top: 1200px;
      left: 250px;
      z-index: 100;

      img {
        width: 862px;
        height: 265px;
      }
      .more-lzai {
        width: 1200px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 80px;
        font-style: italic;
        letter-spacing: 10px;
       
      }
    }
   .more1 {
      position: absolute;
      top: 10200px;
      left: 250px;
      z-index: 100;

      img {
        width: 862px;
        height: 265px;
      }
      .more-lzai {
        width: 1200px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 80px;
        font-style: italic;
        letter-spacing: 10px;
         
      }
    }
    .text-bg {
      overflow: hidden;
      position: absolute;
      top: 1410px;
      left: 200px;
      width: 1070px;
      height: 453px;
      z-index: 100;
      background: url("../assets/text-bg.png") no-repeat;
      background-size: cover;
      padding: 0 80px;
      .bg-title {
        margin-top: 70px;
        // margin-left: 80px;
        font-size: 65px;
      }
      .bg-subtitle {
        // margin-left: 80px;
        width: 920px;
        font-size: 44px;
      }
      .dot-d {
        margin-left: 30px;
        width: 49px;
        height: 49px;
        border-radius: 50%;
        background-color: #7f7f7f;
        border: 2px solid #000;
      }
      .active {
        background: #ffbb00;
      }
    }
    .text-bg1 {
      overflow: hidden;
      position: absolute;
      top: 11500px;
      left: 200px;
      width: 1070px;
      height: 453px;
      z-index: 100;
      background: url("../assets/text-bg.png") no-repeat;
      background-size: cover;
      padding: 0 80px;
      .bg-title {
        margin-top: 70px;
        // margin-left: 80px;
        font-size: 65px;
      }
      .bg-subtitle {
        // margin-left: 80px;
        width: 920px;
        font-size: 44px;
      }
      .dot-d {
        margin-left: 30px;
        width: 49px;
        height: 49px;
        border-radius: 50%;
        background-color: #7f7f7f;
        border: 2px solid #000;
      }
      .active {
        background: #ffbb00;
      }
      button{
        width: 400px;
        height: 80px;
        font-size: 70px;
      }
    }
    .text-bg2 {
      overflow: hidden;
      position: absolute;
      top: 12050px;
      left: 300px;
      width: 1270px;
      height: 880px;
      z-index: 100;
     
      background-size: cover;
      padding: 0 80px;
      .bg-title {
        margin-top: 70px;
        // margin-left: 80px;
        font-size: 65px;
      }
      .bg-subtitle {
        // margin-left: 80px;
        width: 920px;
        font-size: 44px;
      }
      .dot-d {
        margin-left: 30px;
        width: 49px;
        height: 49px;
        border-radius: 50%;
        background-color: #7f7f7f;
        border: 2px solid #000;
      }
      .active {
        background: #ffbb00;
      }
      img{
        width: 100%;
        height: 730px;
        font-size: 70px;
      }
      .van-swipe{
        width: 1300px;
        height: 730px;
      }
      .al-item{
        justify-content: center;
      }
    }
    .dun {
      position: absolute;
      top: 1430px;
      right: 50px;
      width: 428;
      height: 482px;
    }
    .diao-zhe {
      position: absolute;
      top: 1430px;
      right: 150px;
      width: 493px;
      height: 519px;
    }
    .san-ji {
      position: absolute;
      top: 2280px;
      left: 329px;

      z-index: 100;
      img {
        width: 1139px;
        height: 846px;
      }
    }
      .san-ji1 {
      position: absolute;
      top: 12080px;
      left: 329px;

      z-index: 100;
      img {
        width: 1139px;
        height: 846px;
      }
    }
    .duihua {
      z-index: 2;
      position: absolute;
      top: 2850px;
      left: 420px;
      z-index: 100;

      .duihua-lzai {
        padding: 60px;
        position: absolute;
        font-size: 130px;
        top: 100px;
        text-align: center;
        // left: 50%;
      }
      img {
        width: 956px;
        height: 586px;
      }
    }
   .duihua1 {
      z-index: 2;
      position: absolute;
      top: 12750px;
      left: 220px;
      z-index: 100;

      .duihua-lzai {
        padding: 60px;
        position: absolute;
        font-size: 80px;
        top: 123px;
        text-align: center;
        // left: 50%;
      }
      img {
        width: 1456px;
        height: 586px;
      }
    }

    .qu-tmd {
      // width: 100%;
      position: absolute;
      top: 3620px;
      left: 409px;
      transform: rotate(-30deg);
      z-index: 10;
      display: flex;
      flex-direction: column;
      
      .titles {
        font-size: 240px;
      }
      .sub-titles {
        font-size: 90px;
      }
    }
    .diao-si {
      position: absolute;
      top: 3800px;
      right: 0;
      width: 1500px;
      height: 1467px;
      z-index: 100;
    }

    .why {
      position: absolute;
      top: 4950px;
      left: 622px;
      .why-text {
        position: absolute;
        top: 100px;
        font-size: 100px;
       
      }
      img {
        width: 851px;
        height: 280px;
      }
    }

    .big-txt {
      position: absolute;
      top: 5250px;
      left: 222px;
      width: 1492px;
      height: 727px;
      z-index: 100;
    }
    .big-txt1 {
      position: absolute;
      top: 6050px;
      left: 222px;
      width: 1492px;
      height: 727px;
      z-index: 100;
    }
    .big-txt2 {
      position: absolute;
      top: 6850px;
      left: 222px;
      width: 1492px;
      height: 727px;
      z-index: 100;
    }
    .big-txt3 {
      position: absolute;
      top: 7650px;
      left: 222px;
      width: 1492px;
      height: 727px;
      z-index: 100;
    }
    .jingshne-x {
      position: absolute;
      top: 5390px;
      left: 300px;
      width: 349px;
      height: auto;
      z-index: 200;
      
    }
    .jingshne-x1 {
      position: absolute;
      top: 6190px;
      left: 300px;
      width: 349px;
      height: auto;
      z-index: 200;
      
    }
    .jingshne-x2 {
      position: absolute;
      top: 7020px;
      left: 300px;
      width: 349px;
      height: auto;
      z-index: 200;
      
    }
    .jingshne-x3 {
      position: absolute;
      top: 7770px;
      left: 300px;
      width: 349px;
      height: auto;
      z-index: 200;
      
    }
    .jindu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 5310px;
      left: 300px;
      z-index: 200;
      width: 1350px;
      .text-zi {
        font-size: 100px;
      }
    }

    // .lzai-number {
    //   position: absolute;
    //   top: 5610px;
    //   left: 250px;
    //   z-index: 200;
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-around;
    //   .number {
    //     font-size: 70px;
    //     margin: 0 20px;
    //   }
    // }

    .daibi-mame {
      position: absolute;
      top: 5420px;
      left: 650px;
      z-index: 200;
      display: flex;
      width:1000px;
      align-items: center;
      .title-name {
        font-size: 95px;
        margin-left: 50px;
      }
      .title-text {
        font-size: 55px;
        margin-left: 50px;
      }
    }
    .daibi-mame1 {
      position: absolute;
      top: 6170px;
      left: 650px;
      z-index: 200;
      display: flex;
      width:1000px;
      align-items: center;
      .title-name {
        font-size: 95px;
        margin-left: 50px;
      }
      .title-text {
        font-size: 55px;
        margin-left: 50px;
      }
     
    }
    .daibi-mame2 {
      position: absolute;
      top: 7000px;
      left: 650px;
      z-index: 200;
      display: flex;
      width:1000px;
      align-items: center;
      .title-name {
        font-size: 95px;
        margin-left: 50px;
      }
      .title-text {
        font-size: 55px;
        margin-left: 50px;
      }
    }
    .daibi-mame3 {
      position: absolute;
      top: 7900px;
      left: 650px;
      z-index: 200;
      display: flex;
      width:1000px;
      align-items: center;
      .title-name {
        font-size: 95px;
        margin-left: 50px;
      }
      .title-text {
        font-size: 55px;
        margin-left: 50px;
      }
    }
    .sdt-bi {
      position: absolute;
      top: 5450px;
      left: 270px;
      width: 328px;
      text-align: center;
      line-height: 115px;
      height: 115px;
      background: url("../assets/green-bg.png") no-repeat;
      background-size: cover;
      z-index: 200;

      .sdt-number {
        font-size: 70px;
      }
    }
    .sdt-bi2 {
      position: absolute;
      top: 5450px;
      left: 620px;
      width: 328px;
      text-align: center;
      line-height: 115px;
      height: 115px;
      background: url("../assets/green-bg.png") no-repeat;
      background-size: cover;
      z-index: 200;

      .sdt-number {
        font-size: 70px;
      }
    }
    .sdt-bi3 {
      position: absolute;
      top: 5450px;
      left: 970px;
      width: 328px;
      text-align: center;
      line-height: 115px;
      height: 115px;
      background: url("../assets/green-bg.png") no-repeat;
      background-size: cover;
      z-index: 200;

      .sdt-number {
        font-size: 70px;
      }
    }
    .sdt-bi4 {
      position: absolute;
      top: 5450px;
      left: 1320px;
      width: 328px;
      text-align: center;
      line-height: 115px;
      height: 115px;
      background: url("../assets/green-bg.png") no-repeat;
      background-size: cover;
      z-index: 200;

      .sdt-number {
        font-size: 70px;
      }
    }

    .daibi {
      position: absolute;
      top: 8593px;
      left: 120px;
      width: 1261px;
      text-align: center;
      line-height: 232px;
      height: 232px;
      background: url("../assets/daibi-bg.png") no-repeat;
      background-size: cover;
      .daibi-number {
        font-size: 120px;
        font-style: italic;
        
      }
    }

    .left-txt {
      position: absolute;
      top: 8793px;
      left: 100px;
      width: 980px;
      padding: 60px 0px 0 60px;
      height: 751px;
      background: url("../assets/left-txt.png") no-repeat;
      background-size: cover;
      z-index: 200;

      .daibi-number {
        font-size: 70px;
      }
    }

    .fire {
      position: absolute;
      top: 8793px;
      right: 124px;
      width: 756px;
      height: 723px;
      z-index: 100;
    }
    .big-b {
      position: absolute;
      top: 8793px;
      right: 172px;
      width: 733px;
      height: 869px;
      z-index: 100;
    }
    .big-b1 {
      position: absolute;
      top: 11193px;
      right: 122px;
      width: 633px;
      height: 769px;
      z-index: 100;
    }

    .yijia {
      position: absolute;
      top: 9393px;
      left: 422px;
      width: 1289px;
      text-align: center;
      z-index: 200;

      // line-height: 300px;
      height: 733px;
      background: url("../assets/yijia.png") no-repeat;
      background-size: cover;
      padding: 0 20px;
      .daibi-number {
        position: absolute;
        top: 390px;
        font-size: 130px;
        font-style: italic;
       
      }
    }

    .lian-xi {
      position: absolute;
      top: 11023px;
      left: 496px;
      width: 859px;
      text-align: center;
      line-height: 300px;
      z-index: 200;
      height: 232px;
      background: url("../assets/why.png") no-repeat;
      background-size: cover;
      .daibi-number {
        font-size: 110px;
        font-style: italic;
        font-weight: 700;
        
      }
    }

    .qi-fly {
      position: absolute;
      top: 9633px;
      left: 0;
      width: 800px;
      height: 947px;
      z-index: 200;
    }

    .foodrer {
      position: absolute;
      top: 10510px;
      left: 109px;
      width: 1720px;
      text-align: center;
      height: 469px;
      padding: 0 200px;
      background: url("../assets/fooder.png") no-repeat;
      background-size: cover;
      z-index: 100;
      .daibi-number {
        width: 1650px;
        position: absolute;
        top: 100px;
        left: 20px;
        font-size: 50px;
        font-weight: 700;
      }
    }

    .caibao {
      position: absolute;
      top: 750px;
      left: 0;
      width: 1908px;
      height: 8319px;
      z-index: 10;
      
    }
  }
  ::v-deep .el-progress {
    width: 1200px;
    margin: -10px 20px 0;
  }
  ::v-deep .el-progress--text-inside .el-progress-bar {
    border: 5px solid #000;
    border-radius: 50px;
  }
  ::v-deep .el-progress-bar__outer {
    background-color: #d1d1d1;
    height: 74px !important;
  }
  ::v-deep .el-progress-bar__inner {
    background-color: #febe25;
  }
  ::v-deep .el-carousel__container {
    height: 350px;
  }
  // .zhezhao {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba(0, 0, 0, 0.5);
  //   z-index: 10000;
  // }
  .popup {
    
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 600px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    z-index: 10000000;
    .p-title {
      // justify-content: center;
      img {
        margin: 180px 0 80px 0;
        width: 128px;
        height: 128px;
      }
      .succe {
        font-size: 100px;
      }
    }
  }
}
</style>
